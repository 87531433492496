import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./styles/footer.component.scss',
                './styles/footer.ios.scss',
                './styles/footer.md.scss',
                './styles/footer.responsive.scss']
})
export class FooterComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
