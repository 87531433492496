import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./styles/topbar.component.scss',
                './styles/topbar.ios.scss',
                './styles/topbar.md.scss',
                './styles/topbar.responsive.scss']
})
export class TopbarComponent implements OnInit {

    action= 'search';
    constructor() { }

    ngOnInit(): void { }
}
