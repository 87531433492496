<!--====== Footer PART START ======-->
<footer class="footer-area footer-area-two" style="background-image: url(https://webtend.net/demo/html/omnivus_preview/assets/img/footer-bg.jpg);">
    <div class="container">
        <div class="row footer-widgets">
            <div class="col-lg-4 col-md-6 col-md-7">
                <div class="widget about-widget">
                    <div class="logo">
                        <img src="assets/img/logowhite.png" alt="">
                    </div>
                    <!-- <h1>CHANNEL<span>Solutions</span></h1> -->
                    <p>The web has changed a lot since Vitaly posted his first article back in 2006. The team at
                        Smashing has changed too, as have the things that we bring to our community onferences,
                        books, and our membership added to the online magazine.</p>
                    <p>One thing that hasn’t changed is that we’re a small team — with most of us not working</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 offset-xl-1">
                <div class="widget nav-widget">
                    <h4 class="widget-title">Pages</h4>
                    <div class="footer-list">
                        <ul>
                            <li><a routerLink="/"><i class="fal fa-angle-right"></i> Home</a></li>
                            <li><a routerLink="/aboutfullview"><i class="fal fa-angle-right"></i> About</a></li>
                            <li><a routerLink="/services/azure"><i class="fal fa-angle-right"></i> Services</a></li>
                            <li><a routerLink="/career/careercenter"><i class="fal fa-angle-right"></i> Career</a></li>
                            <li><a routerLink="/gallery"><i class="fal fa-angle-right"></i> Gallery</a></li>
                            <li><a routerLink="/contactus"><i class="fal fa-angle-right"></i> Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-md-6">
                <div class="widget contact-widget">
                    <h4 class="widget-title">Working Hours</h4>
                    <ul>
                        <li>Monday - Friday: 7:00 - 17:00</li>
                        <li>Saturday: 7:00 - 12:00</li>
                        <li>Sunday and holidays: 8:00 - 10:00</li>
                    </ul>
                    <p><span>For more then 30 years,</span> IT Service has been a reliable partner in the field of
                        logistics and cargo forwarding.</p>
                    <!-- <a><i class="fal fa-angle-right"></i>Discover More</a> -->
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <p>Copyright By@ <a>Channel Solutions</a> - 2021</p>
        </div>
    </div>
</footer>
<!--====== Footer PART ENDS ======-->