<!--====== Header Part Start ======-->
<header class="header-one header-full-width sticky-header">
    <div class="header-topbar d-none d-sm-block">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between">
                <div class="col-sm-auto col-12">
                    <ul class="contact-info text-center">
                        <li><a><i class="fal fa-envelope"></i> avijith@channelsolutions.llc</a></li>
                        <li><a><i class="fal fa-phone"></i> +1 (708)669-6655</a></li>
                    </ul>
                </div>
                <div class="col-sm-auto col-12">
                    <div class="social-icon text-center">
                        <ul>
                            <li><a><i class="fab fa-facebook-f"></i></a></li>
                            <li><a><i class="fab fa-twitter"></i></a></li>
                            <li><a><i class="fab fa-behance"></i></a></li>
                            <li><a><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a><i class="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-navigation">
        <div class="container-fluid d-flex align-items-center justify-content-between">
            <div class="header-left">
                <!-- <h1 routerLink="/">CHANNEL<span>Solutions</span></h1> -->
                <div class="site-logo" routerLink="/">
                    <a><img src="assets/img/logo.png" alt="Omnivus"></a>
                </div>
            </div>
            <div class="header-right d-flex align-items-center justify-content-end">
                <div class="site-nav-menu">
                    <ul class="primary-menu">
                        <li class="current">
                            <a class="nav-link" routerLink="/">Home</a>
                        </li>
                        <li>
                            <a class="nav-link" routerLink="/aboutfullview">About</a>
                        </li>
                        <li>
                            <a>Services</a>
                            <ul class="submenu">
                                <li>
                                    <a>Microsoft</a>
                                    <ul class="submenu">
                                        <li><a routerLink="/services/azure">Azure</a></li>
                                        <li><a routerLink="/services/sqlserver">SQL Server & BI</a></li>
                                        <li><a routerLink="/services/dotnet">.Net</a></li>
                                        <li><a routerLink="/services/msdynamic">Microsoft Dynamic</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a>Oracle</a>
                                    <ul class="submenu">
                                        <li><a routerLink="/services/oraclecloud">Oracle Cloud</a></li>
                                        <li><a routerLink="/services/middleware">Middleware & Integration</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a>Salesforce</a>
                                    <ul class="submenu">
                                        <li><a routerLink="/services/sfimplementation">Salesforce Implenation</a></li>
                                        <li><a routerLink="/services/sflighting">Salesforce Lightning</a></li>
                                        <li><a routerLink="/services/sfproducts">Salesforce Products</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink="/services/datawarehouse">Data Warehouse</a></li>
                                <li>
                                    <a>Application Development</a>
                                    <ul class="submenu">
                                        <li><a routerLink="/services/webapplication">Web Applications</a></li>
                                        <li><a routerLink="/services/mobileapplication">Mobile Applications</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink="/services/itoutsourcing">IT Outsourcing</a></li>
                                <li><a routerLink="/services/projectmanagement"> Project Management </a></li>
                                <li>
                                    <a>Staffing</a>
                                    <ul class="submenu">
                                        <li><a routerLink="/services/itconsulting">IT Consulting</a></li>
                                        <li><a routerLink="/services/itnonconsulting">Non IT Consulting</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink="/services/productsupportservices">Product Support Services </a></li>
                            </ul>
                        </li>
                        <li>
                            <a class="nav-link" routerLink="/gallery">Gallery</a>
                        </li>
                        <li>
                            <a>Career</a>
                            <ul class="submenu">
                                <li><a routerLink="/career/careercenter">Career center</a></li>
                                <li><a routerLink="/career/hr">HR</a></li>
                                <li><a routerLink="/career/joinourteam">Join our team</a></li>
                                <li><a routerLink="/career/interviewprocess">Interview process & tips</a></li>
                                <li><a routerLink="/career/careerconnection">Your career connection</a></li>
                                <li><a routerLink="/career/currentjobopenings">Current job openings</a></li>
                            </ul>
                        </li>
                        <li>
                            <a class="nav-link" routerLink="/contactus">Contact</a>
                        </li>
                    </ul>
                    <a class="nav-close"><i class="fal fa-times"></i></a>
                </div>
                <div class="header-extra d-flex align-items-center">
                    <!-- <div class="search-widget">
                        <a class="search-icon"><i class="fal fa-search"></i></a>

                        <div class="search-form">
                            <form action="#">
                                <input type="search" placeholder="Type keywords & hit enter">
                            </form>

                            <a class="search-close"><i class="fal fa-times"></i></a>
                        </div>
                    </div> -->
                    <div class="offcanvas-widget d-lg-block d-none">
                        <div class="offcanvas-icon">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                    <div class="nav-toggler">
                        <span></span><span></span><span></span>
                    </div>
                    <!-- <div class="navbar-btn">
                        <a>Free Consulting <i class="fal fa-long-arrow-right"></i></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</header>
<!--====== Header Part End ======-->
